<template>
	<DialogBase
		:dialogWidth="1100"
		:dialogModel="dialogModel"
		@closeDialog="val => closeDialog(val)"
		v-bind="$attrs"
	>
		<template v-slot:title>
			{{ selectedModel ? `Update` : `Create` }} Template
		</template>

		<v-form id="form" @submit.prevent v-model="isValidForm" ref="from">
			<FormMaker
				@change="formClickedDebounced"
				@click="formClickedDebounced"
				:schema="form_structure"
				:model="form_data"
			></FormMaker>
		</v-form>

		<template v-slot:footer>
			<v-btn color="accent" outlined block @click="submit()">
				{{ selectedModel ? `Update` : `Create` }}
			</v-btn>
		</template>
	</DialogBase>
</template>

<script>
	import { debounce } from "lodash";
	import { required, validAlphaNum } from "@/plugins/validationRules.js";
	import FormMaker from "@/components/FormMaker";
	import DialogBase from "@/components/dialogs/Base";
	export default {
		components: { DialogBase, FormMaker },
		props: {
			selectedTemplateOptionIndexId: { required: false, default: null },
			dialogModel: { required: true, type: Boolean },
			selectedModel: { required: true, default: null },
			user: { required: true, default: null }
		},
		data() {
			return {
				scrollToId: null,
				isValidForm: false,
				systemTemplates: [],
				systemShortCodes: [],
				form_data: {
					payload: {
						name: null,
						subject: null,
						body_type: "text",
						body_options: [{ is_selected: "Yes", body: null }]
					}
				}
			};
		},
		mounted() {
			this.getSystemTemplates();
			this.getSystemShortCodes();
			if (this.selectedModel) {
				this.form_data = Object.assign({}, this.selectedModel);
				this.scrollToId = this.selectedTemplateOptionIndexId;
			} else {
			}
		},
		computed: {
			form_structure() {
				let form = {
					payload: {
						name: {
							type: "select",
							name: "name",
							label: "System Category Name",
							...this.$helpers.formFieldsBasicLayout,
							items: this.systemTemplates,
							itemText: "payload.name",
							itemValue: "payload.name",
							rules: [required()],
							disabled: !!this.selectedModel
						},
						body_type: {
							type: "btn-toggle",
							name: "body_type",
							label: "Body Type?",
							...this.$helpers.formFieldsBasicLayout,
							options: ["html", "text"],
							mandatory: true,
							rules: [required],
							col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 }
						},
						add_body_option: {
							type: "btn",
							name: "add_body_option",
							label: "Add Option",
							...this.$helpers.formFieldsBasicLayout,
							block: true,
							color: "success",
							col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 }
						},
						body_options: {
							type: "array",
							class: "is-form-section-1",
							col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 },
							ref: "body_options",
							schema: {
								remove_body_option: {
									type: "btn",
									name: "remove_body_option",
									label: "Remove This Body Option",
									...this.$helpers.formFieldsBasicLayout,
									block: true,
									color: "error",
									class: "custom-style py-0 mb-2 px-2",
									col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 }
								},
								is_selected: {
									type: "btn-toggle",
									name: "is_selected",
									label: "Selected as default?",
									...this.$helpers.formFieldsBasicLayout,
									options: ["Yes", "No"],
									mandatory: true,
									rules: [required],
									class: "custom-style py-0 mb-2 px-2",
									col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 }
								},
								name: {
									type: "text",
									name: "name",
									label: "Template Name",
									...this.$helpers.formFieldsBasicLayout,
									rules: [required()],
									col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 }
								},
								subject: {
									type: "text",
									name: "subject",
									label: "Subject",
									...this.$helpers.formFieldsBasicLayout,
									rules: [],
									col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 },
									hidden: this.form_data.payload.body_type != "html"
								},
								body: {
									type:
										this.form_data.payload.body_type == "html"
											? "text-editor"
											: "textarea",
									name: "body",
									label: "Body",
									...this.$helpers.formFieldsBasicLayout,
									rows: 6,
									rules: [],
									col: { cols: 12, sm: 12, md: 7, lg: 8, xl: 8 }
								},
								short_codes: {
									type: "click-to-copy-list",
									name: "short_codes",
									label: "Short codes",
									...this.$helpers.formFieldsBasicLayout,
									isLongList: this.form_data.payload.body_type == "html",
									items: this.systemShortCodes.map(code => {
										return {
											text: code.payload.name,
											value: `{{${code.payload.name}}}`
										};
									}),
									itemText: "value",
									itemValue: "value",
									rules: [],
									col: { cols: 12, sm: 12, md: 5, lg: 4, xl: 4 }
								}
							}
						}
					}
				};
				return form;
			}
		},
		watch: {},
		methods: {
			goToSection(elementId = null) {
				if (elementId) {
					this.$nextTick().then(() => {
						let scrollToElement = document.getElementById(elementId);
						// console.log(elementId, scrollToElement);
						if (scrollToElement) {
							this.$vuetify.goTo(scrollToElement, {
								duration: 200,
								offset: 0,
								easing: "easeInOutCubic",
								container: "#dialog-view-container"
							});
						}
					});
				}
			},
			closeDialog(val = false) {
				this.$emit("closeDialog", val);
			},
			refresh(val = false) {
				this.$emit("refresh");
			},
			formClickedDebounced: debounce(
				function(obj) {
					this.formClicked(obj);
				},
				300,
				{ leading: true, trailing: false }
			),

			async formClicked({
				on,
				id,
				key,
				value,
				params,
				obj,
				data,
				schema,
				parent,
				index,
				event
			}) {
				// console.log(`${on}, ${key}`, params, index, value);

				if (`${on}, ${key}` == `input, payload.name` && value) {
					let selectedTemplate = this.systemTemplates.find(
						template => template?.payload?.name == value
					);
					await this.$nextTick();
					this.form_data.payload = Object.assign({}, selectedTemplate?.payload);
				}

				if (`${on}, ${key}` == `click, payload.add_body_option`) {
					let id = this.form_data.payload.body_options.length + 1;
					this.form_data.payload.body_options.push({
						id: id,
						name: `Default Option ${id}`,
						is_selected: "No",
						body: null
					});

					this.scrollToId = this.form_data.payload.body_options.length - 1;
					this.showNotification(`Option ${id} added.`);
				}

				if (`${on}, ${key}` == `click, remove_body_option`) {
					if (this.form_data.payload.body_options.length > 1) {
						this.form_data.payload.body_options.splice(index, 1);
						this.showNotification(`Option ${index[0] + 1} removed.`, "success");
						this.scrollToId = 0;
					} else {
						this.showNotification("At least 1 option is required.");
					}
				}

				if (`${on}, ${key}` == `input, is_selected`) {
					this.form_data.payload.body_options = this.unselectAllBodyOptions(
						this.form_data.payload.body_options
					);
					this.form_data.payload.body_options[index[0]].is_selected = value;

					this.scrollToId = index[0];
					this.showNotification(
						`Option ${index[0] + 1} marked as selected.`,
						"success"
					);
				}

				this.setDefaultBodyOptions(this.form_data.payload.body_options);

				if (this.scrollToId) {
					this.goToSection(`form-base-payload.body_options-${this.scrollToId}`);
					this.scrollToId = null;
				}
			},
			unselectAllBodyOptions(bodyOptions) {
				return bodyOptions.map(option => {
					return { ...option, is_selected: "No" };
				});
			},
			setDefaultBodyOptions(bodyOptions) {
				let activeBodyOptions = bodyOptions.filter(option => {
					return option.is_selected == "Yes";
				});
				if (activeBodyOptions.length == 0) {
					this.showNotification("At least 1 option must be selected");
					this.form_data.payload.body_options[0].is_selected = "Yes";
				}
			},
			async getSystemTemplates() {
				let data = {
					agent_id: this.user?.agent?.id
				};

				return await this.post(
					`${this.baseUrl}/agent-portal/get-system-templates`,
					data,
					true
				).then(data => {
					if (data == "undefined") return;
					// this.showMessageFromResponseDta(data);
					if (data.code == 200) {
						this.systemTemplates = data.data.system_templates;
					}
				});
			},
			async getSystemShortCodes() {
				let data = {
					agent_id: this.user?.agent?.id
				};

				return await this.post(
					`${this.baseUrl}/agent-portal/get-system-short-codes`,
					data,
					true
				).then(data => {
					if (data == "undefined") return;
					// this.showMessageFromResponseDta(data);
					if (data.code == 200) {
						this.systemShortCodes = data.data.system_short_codes;
					}
				});
			},
			async submit() {
				this.$refs.from.validate();
				if (!this.isValidForm) return;

				let data = {
					...this.form_data,
					agent_id: this.currentUser.agent.id
				};

				if (this.selectedModel)
					return await this.post(
						`${this.baseUrl}/agent-portal/update-agent-template`,
						data,
						true
					).then(data => {
						if (data == "undefined") return;
						this.showMessageFromResponseDta(data);

						if (data.code == 200) {
							this.refresh();
							this.closeDialog();
						}
					});

				return await this.post(
					`${this.baseUrl}/agent-portal/create-agent-template`,
					data,
					true
				).then(data => {
					if (data == "undefined") return;
					this.showMessageFromResponseDta(data);

					if (data.code == 200) {
						this.refresh();
						this.closeDialog();
					}
				});
			}
		}
	};
</script>

<style lang="scss">
</style>
