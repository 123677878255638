<template>
	<div>
		<div>
			<v-btn v-if="isText" color="success" @click="showTemplatesDialog = true">
				My Templates
			</v-btn>

			<v-btn
				v-else
				@click="showTemplatesDialog = true"
				:color="'primary'"
				large
				icon
			>
				<v-icon large>
					mdi-file-cog
				</v-icon>
			</v-btn>
		</div>
		<v-dialog
			v-if="showTemplatesDialog"
			v-model="showTemplatesDialog"
			fullscreen
			scrollable
			persistent
			hide-overlay
			transition="dialog-bottom-transition"
		>
			<v-card>
				<v-card-title class="text-h5 transparent-3">
					My Templates
					<v-spacer></v-spacer>

					<v-btn
						color="success"
						icon
						@click="createUpdateAgentTemplateDialogShow()"
					>
						<v-icon>mdi-pencil</v-icon>
					</v-btn>

					<v-btn color="secondary" icon @click="getAgentTemplates()">
						<v-icon>mdi-refresh</v-icon>
					</v-btn>

					<v-btn color="error" icon @click="showTemplatesDialog = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text>
					<v-card
						v-for="(template, index) in agentTemplates"
						class="my-4 transparent-1"
						:key="index"
						elevation="1"
					>
						<v-card-title class="pa-2 ma-0 transparent-3">
							<h5>
								{{ template.payload.name }}
							</h5>
							<v-spacer></v-spacer>
							<div>
								<v-btn
									color="success"
									small
									icon
									@click="createUpdateAgentTemplateDialogShow(template, null)"
								>
									<v-icon>mdi-update</v-icon>
								</v-btn>
								<v-btn
									@click="deleteAgentTemplate(template.id)"
									color="error"
									icon
									small
								>
									<v-icon>mdi-delete</v-icon>
								</v-btn>
							</div>
						</v-card-title>
						<v-card-text class="pa-2">
							<v-row>
								<v-col
									v-for="(option, i) in template.payload.body_options"
									:cols="colSize(4, 6, 12, 12)"
									:key="i"
								>
									<v-card class="transparent-1" elevation="1">
										<v-card-title class="pa-1 my-0 transparent-2">
											<h6>
												{{ option.name }}
											</h6>
											<v-spacer></v-spacer>
											<div>
												<v-tooltip v-if="option.is_selected == 'Yes'" bottom>
													<template v-slot:activator="{ on, attrs }">
														<v-icon
															color="success"
															v-bind="attrs"
															v-on="on"
															small
															>mdi-check-circle</v-icon
														>
													</template>
													<span>
														This is the default selected option for the
														template.
													</span>
												</v-tooltip>
												<v-btn
													color="success"
													small
													icon
													@click="
														createUpdateAgentTemplateDialogShow(template, i)
													"
												>
													<v-icon small>mdi-update</v-icon>
												</v-btn>
											</div>
										</v-card-title>
										<v-card-text class="py-2" v-html="option.body">
										</v-card-text>
									</v-card>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-card-text>
				<v-card-actions class="transparent-2">
					<v-btn
						block
						outlined
						color="green darken-1"
						@click="showTemplatesDialog = false"
					>
						Close
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<template v-if="createUpdateAgentTemplateDialog">
			<createUpdateAgentTemplateDialog
				@refresh="refresh()"
				@closeDialog="createUpdateAgentTemplateDialogClose()"
				:dialogModel="createUpdateAgentTemplateDialog"
				:selectedModel="selectedTemplate"
				:selectedTemplateOptionIndexId="selectedTemplateOptionIndexId"
				:user="user"
				scrollable
			/>
		</template>
	</div>
</template>

<script>
	import createUpdateAgentTemplateDialog from "@/components/dialogs/createUpdateAgentTemplateDialog";

	export default {
		components: { createUpdateAgentTemplateDialog },
		props: {
			user: { required: true, default: null },
			isText: { required: false, default: true }
		},
		data() {
			return {
				agentTemplates: [],
				showTemplatesDialog: false,
				createUpdateAgentTemplateDialog: false,
				selectedTemplate: null,
				selectedTemplateOptionIndexId: null,
				isValidForm: false
			};
		},
		mounted() {
			this.getAgentTemplates();
		},
		computed: {},
		watch: {
			showTemplatesDialog(newVal) {
				if (newVal) this.getAgentTemplates();
			}
		},
		methods: {
			refresh() {
				this.getAgentTemplates();
				this.$emit("refresh");
			},
			createUpdateAgentTemplateDialogShow(template, optionIndexId = null) {
				this.selectedTemplateOptionIndexId = optionIndexId;
				this.selectedTemplate = template;
				this.createUpdateAgentTemplateDialog = true;
			},
			createUpdateAgentTemplateDialogClose() {
				this.selectedTemplate = null;
				this.createUpdateAgentTemplateDialog = false;
			},
			async getAgentTemplates() {
				let data = {
					agent_id: this.user?.agent?.id
				};

				return await this.post(
					`${this.baseUrl}/agent-portal/get-agent-templates`,
					data,
					true
				).then(data => {
					if (data == "undefined") return;
					// this.showMessageFromResponseDta(data);
					if (data.code == 200) {
						this.agentTemplates = data.data.agent_templates;
					}
				});
			},
			async deleteAgentTemplate(agentTemplateId) {
				if (!confirm("You sure you want to delete this custom agent template."))
					return false;

				let data = {
					agent_template_id: agentTemplateId
				};

				return await this.post(
					`${this.baseUrl}/agent-portal/delete-agent-template`,
					data,
					true
				).then(data => {
					if (data == "undefined") return;
					this.showMessageFromResponseDta(data);
					if (data.code == 200) {
						this.getAgentTemplates();
					}
				});
			}
		}
	};
</script>

